import React from 'react';
import Layout from '../../components/Layout';
import ProjectHeader from '../../components/ProjectHeader';
import DistantGroundsIntro from '../../components/DistantGrounds/DistantGroundsIntro'
import DistantGroundsContent from '../../components/DistantGrounds/DistantGroundsContent'
import Footer from '../../components/Footer';

const IndexPage = () => (
  <Layout>
    <ProjectHeader />
    <DistantGroundsIntro />
    <DistantGroundsContent />
    <Footer />
  </Layout>
);

export default IndexPage;
