import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Image, Box } from 'rebass';
import { SectionLink } from 'react-scroll-section';
import styled from 'styled-components';
import MouseIcon from '../MouseIcon';
import { Hero, Subheading } from '../ProjectHeading';

const Banner = styled(Image)`
margin-bottom: -80px;
`;

const HeaderBox = styled(Box)`
position: absolute;
font-style: italic;

transform: rotate(-10deg);

@media only screen and (max-width: 700px) {
}
`;

const DistantGroundsIntro = () => (
  <StaticQuery
    query={graphql`
      query DistantGroundsIntro {
        contentfulDistantGrounds {
          title
          subtitle
          banner {
            file {
              url
            }
          }
        }
      }
    `}
    render={({ contentfulDistantGrounds }) => {
      return (
        <Fragment>
          <HeaderBox pt={[5, 80, 120]} px={[4, 4, 4]}>
            <Hero
              as="h1"
              color="totalDark"
              fontSize={[5, 6, 8]}
              mb={[2, 3, 4]}
            >
              {contentfulDistantGrounds.title}
            </Hero>

            <Subheading as="h2" color="totalDark" fontSize={[4, 5, 6]} mb={[3, 5]}>
              {contentfulDistantGrounds.subtitle}
            </Subheading>
          </HeaderBox>

          <div>
            <Banner
              mt={[50, 50, -100]}
              src={contentfulDistantGrounds.banner.file.url}
              width="97%"
              alt="Distant Grounds Application with Processing"
            />
          </div>

          <SectionLink section="Distant Grounds Content">
            {({ onClick }) => <MouseIcon onClick={onClick} />}
          </SectionLink>
        </Fragment>
      );
    }}
  />
);

export default DistantGroundsIntro;
