import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Box, Flex } from 'rebass';
import Fade from 'react-reveal/Fade';
import ReactMarkdown from 'react-markdown';
import { ProjectContentCard } from '../ProjectContentCard';
import Section from '../Section';
import markdownRenderer from '../MarkdownRenderer';
import SeeProjects from '../../sections/SeeProjects';
import {
  ProjectImageLeft,
  ProjectImageRight,
  ProjectImageTransparent,
} from '../ProjectImage';
import ProjectTitle from '../ProjectTitle';

const DistantGroundsContent = () => (
  <Section.Container id="Distant Grounds Content">
    <StaticQuery
      query={graphql`
        query DistantGroundsContent {
          contentfulDistantGrounds {
            client {
              client
            }
            challenge {
              challenge
            }
            solution {
              solution
            }
            story {
              story
            }
            realization {
              realization
            }
            contentPictures {
              id
              title
              file {
                url
                fileName
              }
            }
            videoSection
            additionalTitle
            additionalInfo {
              additionalInfo
            }
          }
        }
      `}
      render={({ contentfulDistantGrounds }) => {
        const imgCanvas = contentfulDistantGrounds.contentPictures.filter(
          object => object.title === 'Canvas',
        );
        const imgKaKa1 = contentfulDistantGrounds.contentPictures.filter(
          object => object.title === 'KaKa First Contact',
        );
        const imgKaKa2 = contentfulDistantGrounds.contentPictures.filter(
          object => object.title === 'KaKa Guitar Hero',
        );
        const imgKaKa3 = contentfulDistantGrounds.contentPictures.filter(
          object => object.title === 'KaKa Painted',
        );
        const imgKaKa4 = contentfulDistantGrounds.contentPictures.filter(
          object => object.title === 'KaKa Sunset',
        );

        return (
          <Fade bottom>
            <ProjectContentCard>
              <Flex justifyContent="top" flexWrap="wrap">
                <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>Client</ProjectTitle>
                    <ReactMarkdown
                      source={contentfulDistantGrounds.client.client}
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>

                <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>Challenge</ProjectTitle>
                    <ReactMarkdown
                      source={contentfulDistantGrounds.challenge.challenge}
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>

                <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>Solution</ProjectTitle>
                    <ReactMarkdown
                      source={contentfulDistantGrounds.solution.solution}
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  {imgCanvas.map(img => (
                    <ProjectImageTransparent src={img.file.url} key={img.id} />
                  ))}
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgKaKa1.map(img => (
                      <ProjectImageLeft src={img.file.url} key={img.id} />
                    ))}
                  </Box>

                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgKaKa2.map(img => (
                      <ProjectImageLeft src={img.file.url} key={img.id} />
                    ))}
                  </Box>
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  <Box width={[1, 1, 2 / 3]} px={[2, 3, 4]}>
                    <Fade bottom>
                      <ProjectTitle>Story</ProjectTitle>
                      <ReactMarkdown
                        source={contentfulDistantGrounds.story.story}
                        renderers={markdownRenderer}
                      />
                    </Fade>
                  </Box>
                </Box>

                <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>Realization</ProjectTitle>
                    <ReactMarkdown
                      source={contentfulDistantGrounds.realization.realization}
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgKaKa3.map(img => (
                      <ProjectImageRight src={img.file.url} key={img.id} />
                    ))}
                  </Box>

                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgKaKa4.map(img => (
                      <ProjectImageRight src={img.file.url} key={img.id} />
                    ))}
                  </Box>
                </Box>
              </Flex>
            </ProjectContentCard>

            <ProjectContentCard style={{background: '#000000'}}>
              <Box width={[1, 1, 1]} px={[0, 30, 40]}>
                <iframe
                  title="Distant Grounds Video"
                  width="100%"
                  height="400"
                  src={contentfulDistantGrounds.videoSection}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </Box>
            </ProjectContentCard>

            <ProjectContentCard>
              <Box width={[1, 1, 1]} px={[2, 3, 4]}>
                <Fade bottom>
                  <ProjectTitle>
                    {contentfulDistantGrounds.additionalTitle}
                  </ProjectTitle>
                  <ReactMarkdown
                    source={
                      contentfulDistantGrounds.additionalInfo.additionalInfo
                    }
                    renderers={markdownRenderer}
                  />
                </Fade>
              </Box>
            </ProjectContentCard>
            <SeeProjects />
          </Fade>
        );
      }}
    />
  </Section.Container>
);

export default DistantGroundsContent;
